import React from 'react';
import StepCard from './StepCard';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';  // Ensure you have initialized Firebase Analytics

function Step({ step, activeStep, title, children }) {

    // Function to track card clicks
    const trackCardClick = (cardName) => {
        logEvent(analytics, 'card_click', {
            card_name: cardName,
            step: `Step ${step}`
        });
    };

    return (
        <div className={`step ${activeStep === step ? 'active' : ''}`}>
            <div className="step-content">
                <h2>{title}</h2>
                <div className="card-container">
                    {/* Render children and track when a card is clicked */}
                    {React.Children.map(children, (child) => {
                        // Check if the child is a StepCard and track clicks
                        if (child && child.type === StepCard) {
                            return React.cloneElement(child, {
                                onClick: () => {
                                    trackCardClick(child.props.cardTitle);  // Assuming StepCard has a `cardTitle` prop
                                    if (child.props.onClick) child.props.onClick();
                                }
                            });
                        }
                        return child;
                    })}
                </div>
            </div>
        </div>
    );
}

Step.Card = StepCard;

export default Step;
