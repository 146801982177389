const prices = {
    'EZ Lock Sukkah': {
        '8x10': 350,
        '8x12': 400,
        '10x12': 410,
        '10x16': 425,
        '10x20': 450,
        '12x20': 500,
    },
    'Panel Sukkah': {
        '8x10': 450,
        '8x12': 480,
        '10x12': 500,
        '10x16': 550,
        '10x20': 600,
        '12x18': 650,
        '12x20': 700,
        '16x20': 750,
        '18x20': 800,
        '16x24': 850,
    }
};

export default prices;
