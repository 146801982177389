import React from 'react';

function StepNavigation({ activeStep, totalSteps }) {
    return (
        <div className="step-count">
            {/*Step {activeStep} of {totalSteps}*/}
        </div>
    );
}

export default StepNavigation;
