import React from 'react';
import './ProgressBar.css';

function ProgressBar({ activeStep, onStepChange }) {
    const steps = [
        { id: 1, title: 'Sukkah', icon: '🏠' },  // Step 2 becomes Step 1
        { id: 2, title: 'Service', icon: '🛠️' },  // Step 1 becomes Step 2
        { id: 3, title: 'Dimensions', icon: '📏' },
        { id: 4, title: 'Contact', icon: '👤' },
        { id: 5, title: 'Summary', icon: '📋' },
    ];

    return (
        <div className="progress-bar">
            {steps.map((step, index) => (
                <div
                    key={step.id}
                    className={`progress-step 
                        ${activeStep === step.id ? 'active' : ''} 
                        ${activeStep > step.id ? 'completed' : ''} 
                        ${activeStep < step.id ? 'future' : ''}`}
                    onClick={activeStep >= step.id ? () => onStepChange(step.id) : null}
                >
                    <div className="step-icon-container">
                        <div className="step-icon">{step.icon}</div>
                    </div>
                    <div className="step-title">{step.id}.{step.title}</div>
                    {index < steps.length - 1 && (
                        <div className="progress-line">
                            <div className={`progress-line-fill ${activeStep > step.id ? 'completed' : ''}`}></div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default ProgressBar;
