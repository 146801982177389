import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';

function Step4({ activeStep, contactInfo, setContactInfo, errors, onSubmit }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Update contact info as user types
        setContactInfo({
            ...contactInfo,
            [name]: value,
        });
    };

    const handleInputBlur = (e) => {
        const { name, value } = e.target;

        // Log event when the user has finished interacting with the input field
        logEvent(analytics, 'contact_info_typed', {
            field: name,
            value: value,
            step: 'Step 4: Enter Contact Information',
        });
    };

    const handlePhoneChange = (e) => {
        const { value } = e.target;
        setContactInfo({
            ...contactInfo,
            phone: value, // Update phone state while typing
        });
    };

    const handlePhoneBlur = (e) => {
        const value = e.target.value;
        const cleaned = value.replace(/\D/g, ''); // Remove all non-numeric characters

        let formattedPhone = value;  // Initialize formattedPhone

        if (cleaned.length === 10) {
            formattedPhone = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
            setContactInfo({
                ...contactInfo,
                phone: formattedPhone, // Set the formatted phone number
            });
        }

        // Log the phone number after formatting
        logEvent(analytics, 'contact_info_typed', {
            field: 'phone',
            value: cleaned.length === 10 ? formattedPhone : value,
            step: 'Step 4: Enter Contact Information',
        });
    };

    const handleZipCodeChange = (e) => {
        const { value } = e.target;

        // Allow only numeric input and limit to 5 digits
        const cleanedValue = value.replace(/\D/g, '').slice(0, 5);

        setContactInfo({
            ...contactInfo,
            zipCode: cleanedValue,
        });
    };

    const handleSubmit = () => {
        logEvent(analytics, 'contact_info_submit', {
            contactInfo,
            step: 'Step 4: Enter Contact Information',
        });

        onSubmit();
    };

    return (
        <div className={`step step-${activeStep}`}>
            <h2>Step 4: Enter Contact Information 👤</h2>
            <div className="contact-info">
                <div className="form-group">
                    <label>Full Name</label>
                    <div className="input-icon">
                        <FontAwesomeIcon icon={faUser} />
                        <input
                            type="text"
                            name="fullName"
                            className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
                            value={contactInfo.fullName}
                            onChange={handleInputChange} // Capture changes as user types
                            onBlur={handleInputBlur} // Log when user leaves field
                            placeholder="Enter your full name"
                            autoComplete="name"
                        />
                    </div>
                    {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <div className="input-icon">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <input
                            type="email"
                            name="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            value={contactInfo.email}
                            onChange={handleInputChange} // Capture changes as user types
                            onBlur={handleInputBlur} // Log when user leaves field
                            placeholder="Enter your email address"
                            autoComplete="email"
                        />
                    </div>
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>

                <div className="form-group">
                    <label>Zip Code</label>
                    <div className="input-icon">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <input
                            type="text"
                            name="zipCode"
                            className={`form-control ${errors.zipCode ? 'is-invalid' : ''}`}
                            value={contactInfo.zipCode}
                            onChange={handleZipCodeChange} // Validate and update zip code input
                            onBlur={handleInputBlur} // Log when user leaves field
                            placeholder="Enter your ZIP code"
                            autoComplete="postal-code"
                        />
                    </div>
                    {errors.zipCode && <div className="invalid-feedback">{errors.zipCode}</div>}
                </div>

                <div className="form-group">
                    <label>Phone Number</label>
                    <div className="input-icon">
                        <FontAwesomeIcon icon={faPhone} />
                        <InputMask
                            mask="(999) 999-9999" // Set the input mask
                            value={contactInfo.phone} // Controlled input for phone number
                            onChange={handlePhoneChange} // Update phone state while typing
                            onBlur={handlePhoneBlur} // Format and log on blur
                            placeholder="Enter your phone number"
                            autoComplete="tel"
                            className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                        />
                    </div>
                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </div>

                <button className="btn btn-primary" onClick={handleSubmit}>
                    Done
                </button>
            </div>
        </div>
    );
}

export default Step4;
