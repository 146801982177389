import React from 'react';
import prices from './prices';  // Adjust the import path as necessary
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';  // Ensure Firebase Analytics is imported

function Step3({ activeStep, onStepChange, selections }) {
    const { sukkahType, service } = selections;

    if (!sukkahType || !Object.keys(prices).includes(sukkahType)) {
        return (
            <div className="step-content">
                <h2>Error: Invalid Sukkah Type</h2>
                <p>Please go back and select a valid Sukkah Type.</p>
            </div>
        );
    }

    const handleDimensionSelect = (dimension, price) => {
        // Track the dimension selection using Firebase Analytics
        logEvent(analytics, 'dimension_selection', {
            sukkahType: sukkahType,
            dimension: dimension,
            service: service,
            step: 'Step 3: Choose Dimensions'
        });

        // Adjust price based on the selected service
        if (service === 'Assembly' || service === 'Disassembly') {
            price = price / 2;  // Half the price for a single service
        }

        onStepChange(4, { dimensions: dimension, price }); // Move to Step 4 with dimension and price
    };

    const dimensions = prices[sukkahType];

    return (
        <div className={`step step-${activeStep}`}>
            <h2>Step 3: Choose Dimensions 📏</h2>
            <div className="card-container">
                {Object.keys(dimensions).map((dimension) => (
                    <div
                        key={dimension}
                        className="step-card"
                        onClick={() => handleDimensionSelect(dimension, dimensions[dimension])}
                    >
                        <p>{dimension}</p>
                    </div>
                ))}

                {/* "Other" option */}
                <div
                    className="step-card other-option"
                    onClick={() => handleDimensionSelect('Other', 'TBD')}
                    style={{ gridColumn: 'span 2' }}  // Makes the card span the width of 2 regular cards
                >
                    <p>
                        Other**
                        <br/>
                        <sup className="small-text">**(Priced Upon Request)</sup>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Step3;
