import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faBuilding, faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import './HamburgerMenu.css';
import railImage from './rail.jpg';  // Import the image

function HamburgerMenu() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleMenuClick = (anchor) => {
        toggleMenu();  // Close the menu after selecting an option
        const element = document.getElementById(anchor);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <div className="hamburger" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <div
                className={`menu ${menuOpen ? 'open' : ''}`}
                style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.3)), url(${railImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom'
                }}
            >
                <div className="menu-content">
                    <div className="close-btn" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <Link to="/" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faHome} /> Building
                    </Link>
                    <Link to="/rentals" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBuilding} /> Rentals
                    </Link>
                    <Link to="/about" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faInfoCircle} /> About Us
                    </Link>
                    <Link to="/contact-us" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faEnvelope} /> Contact Us
                    </Link>
                </div>
            </div>
            {menuOpen && <div className="overlay" onClick={toggleMenu}></div>}
        </div>
    );
}

export default HamburgerMenu;
