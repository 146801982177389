export function generateEmailContent({ selections, contactInfo, quoteNumber }) {
    const { service, sukkahType, dimensions, price } = selections;

    // Define the HTML content
    const htmlContent = `
    <div style="padding: 25px; background-color: #ffffff; border-radius: 12px; text-align: left; box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); max-width: 700px; margin: 0 auto;">
        <div style="display: flex; align-items: baseline; justify-content: space-between; margin-bottom: 20px; border-bottom: 1px solid #ddd; padding-bottom: 15px;">
            <h2 style="font-size: 24px; color: #333; margin: 0;">Total Price:</h2>
            <span style="font-size: 30px; color: #333; font-weight: bold;">$${price}</span>
        </div>
        <div style="margin-top: 20px;">
            <h3 style="font-size: 20px; color: #444; margin-bottom: 15px;">Your Selections:</h3>
            <ul style="list-style-type: none; padding: 0;">
                <li style="display: flex; align-items: center; font-size: 18px; color: #555; margin-bottom: 12px;">
                    <span style="margin-right: 12px; font-size: 22px; color: #777;">🔧</span>
                    <strong style="color: #000; font-weight: 600;">Service:</strong> ${service}
                </li>
                <li style="display: flex; align-items: center; font-size: 18px; color: #555; margin-bottom: 12px;">
                    <span style="margin-right: 12px; font-size: 22px; color: #777;">🏠</span>
                    <strong style="color: #000; font-weight: 600;">Sukkah Type:</strong> ${sukkahType}
                </li>
                <li style="display: flex; align-items: center; font-size: 18px; color: #555; margin-bottom: 12px;">
                    <span style="margin-right: 12px; font-size: 22px; color: #777;">📏</span>
                    <strong style="color: #000; font-weight: 600;">Dimensions:</strong> ${dimensions}
                </li>
            </ul>
        </div>
        <div style="margin: 20px 0; border-bottom: 1px solid #ddd;"></div>
        <div style="margin-top: 20px;">
            <h3 style="font-size: 20px; color: #444; margin-bottom: 15px;">Contact Information:</h3>
            <ul style="list-style-type: none; padding: 0;">
                <li style="display: flex; align-items: center; font-size: 18px; color: #555; margin-bottom: 12px;">
                    <span style="margin-right: 12px; font-size: 22px; color: #777;">👤</span>
                    <strong style="color: #000; font-weight: 600;">Name:</strong> ${contactInfo.fullName}
                </li>
                <li style="display: flex; align-items: center; font-size: 18px; color: #555; margin-bottom: 12px;">
                    <span style="margin-right: 12px; font-size: 22px; color: #777;">✉️</span>
                    <strong style="color: #000; font-weight: 600;">Email:</strong> ${contactInfo.email}
                </li>
                <li style="display: flex; align-items: center; font-size: 18px; color: #555; margin-bottom: 12px;">
                    <span style="margin-right: 12px; font-size: 22px; color: #777;">📍</span>
                    <strong style="color: #000; font-weight: 600;">ZIP Code:</strong> ${contactInfo.zipCode}
                </li>
                <li style="display: flex; align-items: center; font-size: 18px; color: #555; margin-bottom: 12px;">
                    <span style="margin-right: 12px; font-size: 22px; color: #777;">📞</span>
                    <strong style="color: #000; font-weight: 600;">Phone:</strong> ${contactInfo.phone}
                </li>
            </ul>
        </div>
        <div style="margin: 20px 0; border-bottom: 1px solid #ddd;"></div>
        <div>
            <h3 style="font-size: 20px; color: #444; margin-bottom: 15px;">Quote Number:</h3>
            <span style="font-size: 18px; font-weight: bold; color: #333;">${quoteNumber}</span>
        </div>
    </div>
  `;

    return htmlContent;
}