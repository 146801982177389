import React from 'react';
import Step from './Step';
import EZ from './EZ.jpg';
import Panel from './Panel.jpeg';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';  // Ensure you have initialized Firebase Analytics

function Step1({ activeStep, onStepChange }) {

    // Function to track service selection
    const trackServiceSelection = (type) => {
        logEvent(analytics, 'sukkahtype_selection', {
            sukkahType: type,
            step: 'Step 1: Choose Your Sukkah Type'
        });
    };

    const handleSukkahTypeSelect = (type) => {
        trackServiceSelection(type);  // Track the service selection
        onStepChange(2, { sukkahType: type }); // Move to Step 2 with the selected sukkah type
    };

    return (
        <Step step={1} activeStep={activeStep} title="Step 1: Choose Your Sukkah Type 🏠">
            <div className="card-container">
                <Step.Card
                    image={Panel}
                    text="Panel Sukkah"
                    nextStep={2}
                    onStepChange={() => handleSukkahTypeSelect('Panel Sukkah')}
                />
                <Step.Card
                    image={EZ}
                    text="EZ Lock Sukkah"
                    nextStep={2}
                    onStepChange={() => handleSukkahTypeSelect('EZ Lock Sukkah')}
                />
            </div>
        </Step>
    );
}

export default Step1;
