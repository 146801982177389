import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Added useLocation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { db, analytics } from '../firebase'; // Import the Firestore and Analytics objects
import { logEvent } from 'firebase/analytics'; // Import Firebase analytics
import { collection, addDoc } from 'firebase/firestore';
import { v1 as uuidv1 } from 'uuid'; // Import UUID v1 for generating unique identifiers
import './ContactUs.css';

const ContactUs = () => {
    const location = useLocation(); // Get the state passed from Premium Rentals page
    const [contactInfo, setContactInfo] = useState({
        fullName: '',
        email: '',
        message: location.state?.message || '', // Pre-fill message if passed in state
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState(''); // For the confirmation message
    const [submitting, setSubmitting] = useState(false); // To manage submission state
    const navigate = useNavigate(); // For navigation to the home page

    // Track that user visited the Contact Us page
    useEffect(() => {
        logEvent(analytics, 'contact_page_viewed');
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactInfo({
            ...contactInfo,
            [name]: value,
        });
    };

    const validateContactInfo = () => {
        const newErrors = {};

        if (!contactInfo.fullName) newErrors.fullName = 'Full name is required';
        if (!contactInfo.email || !/\S+@\S+\.\S+/.test(contactInfo.email)) newErrors.email = 'Valid email is required';
        if (!contactInfo.message) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateContactInfo()) {
            setSubmitting(true); // Start submitting state
            const uuid = uuidv1(); // Generate a UUID v1

            try {
                // Fetch the user's IP address
                const ipResponse = await fetch('https://api.ipify.org?format=json');
                const { ip } = await ipResponse.json();

                // Add contactInfo to Firestore 'contacts' collection along with UUID and IP
                const docRef = await addDoc(collection(db, 'contacts'), {
                    fullName: contactInfo.fullName,
                    email: contactInfo.email,
                    message: contactInfo.message,
                    uuid, // Add UUID v1
                    ip, // Capture the user's IP address
                    timestamp: new Date(), // Add a timestamp
                });

                // Track successful form submission
                logEvent(analytics, 'contact_form_submission', {
                    method: 'email',
                    uuid: uuid,
                });

                console.log('Document written with ID: ', docRef.id);

                // Show the success message
                setSuccessMessage('Thank you for contacting us! We will get back to you soon.');

                // Redirect to home after a brief delay
                setTimeout(() => {
                    navigate('/'); // Redirect to home page
                }, 5000);
            } catch (error) {
                console.error('Error adding document: ', error);
                setErrors({ general: 'An error occurred. Please try again later.' });

                // Track form submission error
                logEvent(analytics, 'contact_form_submission_error', {
                    error: error.message,
                });
            } finally {
                setSubmitting(false); // End submitting state
            }
        }
    };

    return (
        <div className="contact-page">
            <div className={`contact-card ${successMessage ? 'form-hidden' : ''}`}>
                <h3>Contact Us</h3>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="fullName">Name*</label>
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faUser} />
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
                                value={contactInfo.fullName}
                                onChange={handleInputChange}
                                placeholder="Enter your full name"
                                disabled={submitting} // Disable input while submitting
                            />
                        </div>
                        {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Your Email*</label>
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                value={contactInfo.email}
                                onChange={handleInputChange}
                                placeholder="Enter your email address"
                                disabled={submitting} // Disable input while submitting
                            />
                        </div>
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message*</label>
                        <textarea
                            id="message"
                            name="message"
                            className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                            value={contactInfo.message}
                            onChange={handleInputChange}
                            placeholder="Enter your message"
                            disabled={submitting} // Disable input while submitting
                        ></textarea>
                        {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                    </div>
                    {errors.general && <div className="error-message">{errors.general}</div>}
                    <button type="submit" disabled={submitting}>
                        {submitting ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </div>

            {successMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h4>{successMessage}</h4>
                        <p>Redirecting to the home page...</p>
                    </div>
                </div>
            )}

            <div className="business-card">
                <h3>Contact Information</h3> {/* Header Styled Similar to Form Header */}
                <p>
                    <FontAwesomeIcon icon={faEnvelope} /> Email: <a href="mailto:info@sukkahbrothers.com">info@sukkahbrothers.com</a>
                </p>
                <p>
                    <FontAwesomeIcon icon={faPhone} /> Phone: <a href="tel:5162825687">516-282-5687</a>
                </p>
            </div>
        </div>
    );
};

export default ContactUs;
