import React from 'react';

function StepCard({ image, text, onStepChange, nextStep }) {
    const handleClick = () => {
        // Add a slight delay to allow the button push effect to complete before navigating
        setTimeout(() => {
            onStepChange(nextStep, text);
        }, 200); // 150ms delay
    };

    return (
        <div className="step-card" onClick={handleClick}>
            <img src={image} alt={text} />
            <p>{text}</p>
        </div>
    );
}

export default StepCard;
