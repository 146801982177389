import React from 'react';
import Step from './Step';
import AseImage from './Ase.png';
import DisImage from './Dis.png';
import BothImage from './Both.png';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';  // Ensure you have initialized Firebase Analytics

function Step2({ activeStep, onStepChange }) {

    // Function to track service selection
    const trackServiceSelection = (service) => {
        logEvent(analytics, 'service_selection', {
            service: service,
            step: 'Step 2: Choose Your Service'
        });
    };

    const handleServiceSelect = (service) => {
        trackServiceSelection(service);  // Track the service selection
        onStepChange(3, { service }); // Move to Step 3 with the selected service
    };

    return (
        <Step step={2} activeStep={activeStep} title="Step 2: Choose Your Service 🛠️">
            <div className="card-container">
                <Step.Card
                    image={AseImage}
                    text="Assembly"
                    nextStep={3}
                    onStepChange={() => handleServiceSelect('Assembly')}
                />
                <Step.Card
                    image={DisImage}
                    text="Disassembly"
                    nextStep={3}
                    onStepChange={() => handleServiceSelect('Disassembly')}
                />
                <Step.Card
                    image={BothImage}
                    text="Both"
                    nextStep={3}
                    onStepChange={() => handleServiceSelect('Both')}
                />
            </div>
        </Step>
    );
}

export default Step2;
