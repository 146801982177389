// src/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-HJGMvGBoItco9K1X-L-pKYdGhwSR7zU",
    authDomain: "sukkahbrothers-alpha.firebaseapp.com",
    projectId: "sukkahbrothers-alpha",
    storageBucket: "sukkahbrothers-alpha.appspot.com",
    messagingSenderId: "1045588546861",
    appId: "1:1045588546861:web:b066dcf3bcc045c556efa4",
    measurementId: "G-F120KKEN6P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

// // Log to check if analytics is initialized
// console.log('Analytics Initialized:', analytics);
//
// // Make Firebase accessible from the window object for debugging in the console
// window.firebaseApp = app;
// window.analytics = analytics;
// window.db = db;


// Export the initialized Firebase app for use in other parts of your project
export { app, analytics, db };
