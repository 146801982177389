import React from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { logEvent } from 'firebase/analytics'; // Import Firebase analytics
import { analytics } from '../firebase'; // Import initialized Firebase analytics
import './PremiumRentals.css'; // You can reuse or modify the About Us CSS for consistency

const PremiumRentals = () => {
    const navigate = useNavigate();

    const handleInquiryClick = () => {
        // Log the event when the "Inquire Now" button is clicked
        logEvent(analytics, 'premium_rentals_inquiry', {
            step: 'Premium Rentals',
            action: 'CTA Clicked',
        });

        // Redirect to Contact Us page with a pre-filled message in the textarea
        navigate('/contact-us', { state: { message: 'I would like to inquire about your premium rental offering.' } });
    };

    return (
        <div className="premium-rentals-page">
            <div className="premium-rentals-header">
                <h1>Premium Rentals Available Upon Request</h1>
                <p>
                    Our premium rentals include <strong>free white-glove assembly</strong> and <strong>disassembly</strong>.
                </p>
                <button className="cta-button" onClick={handleInquiryClick}>
                    Inquire Now
                </button>
            </div>
        </div>
    );
};

export default PremiumRentals;
