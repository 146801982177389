// Header.js

import React from 'react';
import HamburgerMenu from './HamburgerMenu';

function Header({ isAnnouncementVisible, onStepChange, onRestart }) {
    return (
        <div className={`header ${isAnnouncementVisible ? 'with-announcement' : ''}`}>
            <div className="header-content">
                <HamburgerMenu onStepChange={onStepChange} onRestart={onRestart} />
                <h1 className="site-name">
                    <span className="hebrew">סוכ</span>
                    <span className="hebrewd">ד</span>
                    <span className="hebrewh">ו</span>
                    <span className="english"> Brothers</span>
                </h1>
            </div>
        </div>
    );
}

export default Header;
