import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';  // Import Firebase Analytics functions
import { analytics } from './firebase';  // Assuming Firebase Analytics is initialized
import Header from './components/Header';
import AnnouncementBar from './components/AnnouncementBar';
import StepNavigation from './components/StepNavigation';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import ProgressBar from './components/ProgressBar';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import PremiumRentals from './components/PremiumRentals';
import './App.css';

function App() {
    const [activeStep, setActiveStep] = useState(1);
    const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(true);
    const [selections, setSelections] = useState({
        sukkahType: '',
        dimensions: '',
        price: 0,
        service: '',
    });
    const [contactInfo, setContactInfo] = useState({
        fullName: '',
        email: '',
        zipCode: '',
        phone: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [stepStartTime, setStepStartTime] = useState(new Date());  // For tracking time spent on each step

    const navigate = useNavigate();

    // Disable Google Analytics if the query parameter ?disableAnalytics=true is present
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const disableAnalytics = urlParams.get('disableAnalytics') === 'true';

        // Disable or enable analytics collection based on the query parameter
        setAnalyticsCollectionEnabled(analytics, !disableAnalytics);

        // Optionally, log this action
        if (disableAnalytics) {
            console.log("Analytics disabled for this session");
        } else {
            console.log("Analytics enabled for this session");
        }
    }, []);

    // Track duration spent on a step when it changes
    useEffect(() => {
        if (activeStep === 5) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    }, [activeStep]);

    const handleStepChange = (step, data) => {
        const stepEndTime = new Date();
        const timeSpent = stepEndTime - stepStartTime;  // Time spent in ms

        // Combine step_completed and step_duration into a single event
        logEvent(analytics, 'step_completed', {
            step: `Step ${activeStep}`,
            duration_ms: timeSpent,  // Add the duration as a parameter
        });

        setStepStartTime(new Date());  // Reset the time tracker for the next step

        if (data) {
            setSelections((prevSelections) => ({
                ...prevSelections,
                ...data,
            }));
        }

        if (step < activeStep) {
            logEvent(analytics, 'reconfigure', {
                current_step: `Step ${activeStep}`,
                action: 'User Went Back',
            });
        }

        setActiveStep(step);
    };

    const startConfigurator = () => {
        logEvent(analytics, 'configurator_started');
        setStepStartTime(new Date());  // Start timing Step 1
        navigate('/steps');
        setActiveStep(1);
    };

    const validateContactInfo = () => {
        const newErrors = {};

        if (!contactInfo.fullName) newErrors.fullName = 'Full name is required';
        if (!contactInfo.email || !/\S+@\S+\.\S+/.test(contactInfo.email)) newErrors.email = 'Valid email is required';
        if (!contactInfo.zipCode || !/^\d{5}$/.test(contactInfo.zipCode)) newErrors.zipCode = 'Valid zip code is required';
        if (!contactInfo.phone || !/^\(\d{3}\) \d{3}-\d{4}$/.test(contactInfo.phone)) newErrors.phone = 'Valid phone number is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleStep4Submit = () => {
        if (validateContactInfo()) {
            // logEvent(analytics, 'form_submitted', {
            //     form: 'Contact Info',
            //     step: 'Step 4',
            // });

            setActiveStep(5);

            logEvent(analytics, 'completed_configurator', {
                step: 'Step 5',
                action: 'User Completed the Process',
            });
        }
    };

    const handleAnnouncementClose = () => {
        setIsAnnouncementVisible(false);
    };

    return (
        <div>
            <AnnouncementBar isVisible={isAnnouncementVisible} onClose={handleAnnouncementClose} />
            <div className={`container ${isAnnouncementVisible ? 'with-announcement' : ''}`}>
                <Header isAnnouncementVisible={isAnnouncementVisible} />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div className="landing">
                                <h1>Fast, Reliable, and Trusted - <br /> Get Your Quote in as little as 15 Seconds!</h1>
                                <div className="start-cta">
                                    <button className="btn" onClick={startConfigurator}>
                                        Start Now
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <Route
                        path="/steps"
                        element={
                            <div className={`step-container step-${activeStep}`}>
                                <ProgressBar activeStep={activeStep} onStepChange={handleStepChange} />
                                <div className="step-content">
                                    {activeStep === 1 && <Step1 activeStep={activeStep} onStepChange={handleStepChange} />}
                                    {activeStep === 2 && <Step2 activeStep={activeStep} onStepChange={handleStepChange} selections={selections} />}
                                    {activeStep === 3 && <Step3 activeStep={activeStep} onStepChange={handleStepChange} selections={selections} />}
                                    {activeStep === 4 && (
                                        <Step4
                                            activeStep={activeStep}
                                            onStepChange={handleStepChange}
                                            contactInfo={contactInfo}
                                            setContactInfo={setContactInfo}
                                            validateContactInfo={validateContactInfo}
                                            errors={errors}
                                            onSubmit={handleStep4Submit}
                                            selections={selections}
                                        />
                                    )}
                                    {activeStep === 5 && (
                                        <Step5
                                            activeStep={activeStep}
                                            selections={selections}
                                            isLoading={isLoading}
                                            contactInfo={contactInfo}
                                        />
                                    )}
                                </div>
                            </div>
                        }
                    />
                    <Route path="/rentals" element={<PremiumRentals />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/about" element={<AboutUs />} />
                </Routes>
                <StepNavigation activeStep={activeStep} onStepChange={handleStepChange} />
            </div>
        </div>
    );
}

export default function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}
