import React, { useState } from 'react';
import './AnnouncementBar.css';

const AnnouncementBar = ({ isVisible, onClose }) => {
    if (!isVisible) return null;

    return (
        <div className="announcement-bar">
            <span className="announcement-text">
                Every <strong>Sukkah</strong> supports families in <strong>Israel</strong> affected by the war
                {/*<a href="#learn-more" className="learn-more-link">Learn More</a> */}
                <span> </span>
                <span className="flag-emoji">🇮🇱</span>
            </span>
            <span className="close-btn" onClick={onClose}>×</span>
        </div>
    );
};

export default AnnouncementBar;
