import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import './AboutUs.css';
import job1 from './job1.jpeg';
import job2 from './job2.jpeg';
import job3 from './job3.jpeg';
import AseImage from "./Ase.png";

const AboutUs = () => {
    // Boolean flags for each section
    const [showTestimonials, setShowTestimonials] = useState(false); // Testimonials disabled by default
    const [showTwitter, setShowTwitter] = useState(false); // Twitter disabled by default

    useEffect(() => {
        // Load the Twitter widget script if the Twitter section is enabled
        if (showTwitter) {
            const script = document.createElement('script');
            script.src = 'https://platform.twitter.com/widgets.js';
            script.async = true;
            document.body.appendChild(script);
        }
    }, [showTwitter]);

    return (
        <div className="about-page">
            {/* Hero Section */}
            <div className="hero-section">
                <h1>Welcome to Sukkah Brothers</h1>
                <p>Your trusted partner in making Sukkot simple.</p>
            </div>

            <div className="about-content">
                {/* Our Journey Section */}
                <div className="about-card journey-card">
                    <h3>Our Journey</h3>
                    <p>
                        Sukkah Brothers was founded 7 years ago by a team of brothers who aimed to provide quality Sukkah
                        building services to our clientele. Our trustworthy team is sure to help personalize your Sukkah in
                        a way that best suits your needs. Catering to the Five Towns and greater New York area, we hope to
                        provide a seamless, stress-free Sukkah experience for you and your family!
                    </p>
                </div>

                {/* Our Work Section */}
                <div className="about-card gallery-card">
                    <h3>Our Work</h3>
                    <div className="gallery-grid">
                        <img src={job1} alt="Sukkah Project" />
                        <img src={job2} alt="Sukkah Project" />
                        <img src={job3} alt="Sukkah Project" />
                    </div>
                </div>

                {/* Testimonials Section */}
                {showTestimonials && (
                    <div className="about-card testimonials-card">
                        <h3>What Our Clients Say</h3>
                        <div className="testimonial">
                            <div className="testimonial-icon">
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <blockquote>
                                "Sukkah Brothers made our Sukkot truly special. Their attention to detail and dedication to service is unmatched!"
                            </blockquote>
                            <p>- Client Name</p>
                        </div>
                    </div>
                )}

                {/* Twitter Section */}
                {showTwitter && (
                    <a
                        className="twitter-timeline twitter-full-row"
                        data-height="400"
                        href="https://twitter.com/elonmusk?ref_src=twsrc%5Etfw"
                    >
                        Tweets by @elonmusk
                    </a>
                )}

                {/* Mission Section */}
                <div className="about-card mission-card">
                    <h3>Our Mission</h3>
                    <p>
                        Our mission is to provide the highest quality Sukkah building services, ensuring that each family
                        can celebrate Sukkot with peace of mind and joy. We are committed to delivering personalized,
                        reliable, and timely service.
                    </p>
                </div>

                {/*/!* Call to Action Section *!/*/}
                {/*<div className="about-card cta-card">*/}
                {/*    <h3>Ready to Get Started?</h3>*/}
                {/*    <button className="cta-button">Contact Us</button>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default AboutUs;
