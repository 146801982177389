import React, { useState, useEffect } from 'react';
import Step from './Step';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faHome, faRuler, faUser, faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { v1 as uuidv1 } from 'uuid'; // Import UUID v1
import { db } from '../firebase'; // Import Firestore database object
import { collection, addDoc } from "firebase/firestore";
import { generateEmailContent } from './generateEmailContent'; // Import the email content generator
import './Step5.css';

function Step5({ activeStep, isLoading, selections, contactInfo }) {
    const [quoteNumber, setQuoteNumber] = useState('');

    useEffect(() => {
        if (isLoading) {
            // Generate an alphanumeric quote number
            const generateAlphanumericQuote = () => {
                const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                let quote = 'Q';
                for (let i = 0; i < 6; i++) {
                    quote += chars.charAt(Math.floor(Math.random() * chars.length));
                }
                return quote;
            };

            const shortQuoteNumber = generateAlphanumericQuote();
            setQuoteNumber(shortQuoteNumber);

            // Generate a UUID v1 for internal identification
            const uuid = uuidv1();

            // Fetch the user's IP address
            fetch('https://api.ipify.org?format=json')
                .then(response => response.json())
                .then(data => {
                    const ip = data.ip;

                    // Prepare the data to send to Firestore
                    const quoteData = {
                        uuid,  // Store the UUID (v1)
                        quoteNumber: shortQuoteNumber,  // Store the alphanumeric quote number
                        ...selections,
                        ...contactInfo,
                        ip: ip,  // Include the captured IP address
                        timestamp: new Date(),  // Capture the current date and time
                    };

                    // Send the data to Firestore
                    addDoc(collection(db, "quotes"), quoteData)
                        .then(() => {
                            console.log("Quote data written to Firestore successfully");

                            // Generate the email content
                            const emailHtmlContent = generateEmailContent({ selections, contactInfo, quoteNumber: shortQuoteNumber });

                            // Send the email using Firebase function
                            fetch('https://us-central1-sukkahbrothers-alpha.cloudfunctions.net/sendGmail', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    to: contactInfo.email,
                                    subject: 'Your Sukkah Quote Confirmation',
                                    html: emailHtmlContent
                                })
                            })
                                .then(response => response.text())
                                .then(result => console.log('Email sent successfully:', result))
                                .catch(error => console.error('Error sending email:', error));
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });
                })
                .catch(error => {
                    console.error("Error fetching IP address: ", error);
                });
        }
    }, [isLoading, selections, contactInfo]);

    const { dimensions, sukkahType, service, price } = selections;

    return (
        <Step step={5} activeStep={activeStep} title={isLoading ? "Calculating Price..." : "Here's Your Summary 📋"}>

            {isLoading ? (
                <div className="spinner"></div>
            ) : (
                <div className="summary-container">
                    <div className="summary-header">
                        <h2>Total Price:</h2>

                        <span className="summary-price">
                            {price === 'TBD' ? 'TBD*' : `$${price}`}
                        </span>
                    </div>
                    <div className="summary-details">
                    <h3>Your Selections:</h3>
                        <ul>
                            <li><FontAwesomeIcon icon={faTools} className="summary-icon" /><strong>Service:</strong> {service}</li>
                            <li><FontAwesomeIcon icon={faHome} className="summary-icon" /><strong>Sukkah Type:</strong> {sukkahType}</li>
                            <li><FontAwesomeIcon icon={faRuler} className="summary-icon" /><strong>Dimensions:</strong> {price === 'TBD' ? 'Other*' : `$${price}`}</li>
                        </ul>
                    </div>
                    <div className="summary-divider"></div>
                    <div className="summary-contact">
                        <h3>Contact Information:</h3>
                        <ul>
                            <li><FontAwesomeIcon icon={faUser} className="summary-icon" /><strong>Name:</strong> {contactInfo.fullName}</li>
                            <li><FontAwesomeIcon icon={faEnvelope} className="summary-icon" /><strong>Email:</strong> {contactInfo.email}</li>
                            <li><FontAwesomeIcon icon={faMapMarkerAlt} className="summary-icon" /><strong>ZIP Code:</strong> {contactInfo.zipCode}</li>
                            <li><FontAwesomeIcon icon={faPhone} className="summary-icon" /><strong>Phone:</strong> {contactInfo.phone}</li>
                        </ul>
                    </div>
                    <div className="summary-divider"></div>
                    <div className="summary-quote">
                        <h3>Quote Number:</h3>
                        <span>{quoteNumber}</span> {/* Display the generated quote number */}
                    </div>
                </div>
            )}

        </Step>
    );
}

export default Step5;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import React, { useState, useEffect } from 'react';
// import Step from './Step';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTools, faHome, faRuler, faUser, faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
// import { v1 as uuidv1 } from 'uuid'; // Import UUID v1 for unique quote numbers
// import './Step5.css';
//
// // OAuth2 client credentials (Replace with your values)
// const CLIENT_ID = '36181790961-7r4ngm46cbi0e1bocv5bt5bjnf0rpuq8.apps.googleusercontent.com';
// const CLIENT_SECRET = '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';
// const REFRESH_TOKEN = '1//04sgopVn2xvaeCgYIARAAGAQSNwF-L9Irr_lqfXN4CnD6REp8_lea-8V_gqTrKgxMX9QbRlbboK47Oz4ykkw_tEIZ3kHFOFmK7X0';
//
// // Function to send an email using Gmail API
// const sendGmailEmail = (to, subject, htmlContent) => {
//     // Build the Gmail API email body (RFC 2822 format)
//     const message = `To: ${to}\nSubject: ${subject}\n\n${htmlContent}`;
//     const encodedMessage = btoa(unescape(encodeURIComponent(message)))
//         .replace(/\+/g, '-')
//         .replace(/\//g, '_')
//         .replace(/=+$/, '');
//
//     // Step 1: Get OAuth access token using the refresh token
//     fetch('https://oauth2.googleapis.com/token', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: `client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&refresh_token=${REFRESH_TOKEN}&grant_type=refresh_token`,
//     })
//         .then(response => response.json())
//         .then(tokenResponse => {
//             console.log('Access Token:', tokenResponse.access_token);
//
//             // Step 2: Use the access token to send the email via Gmail API
//             fetch('https://www.googleapis.com/gmail/v1/users/me/messages/send', {
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${tokenResponse.access_token}`,
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     raw: encodedMessage,
//                 }),
//             })
//                 .then(response => response.json())
//                 .then(result => console.log('Email sent successfully:', result))
//                 .catch(error => console.error('Error sending email:', error));
//         })
//         .catch(error => console.error('Error fetching access token:', error));
// };
//
// function Step5({ activeStep, isLoading, selections, contactInfo }) {
//     const [quoteNumber, setQuoteNumber] = useState('');
//
//     useEffect(() => {
//         if (isLoading) {
//             // Generate a random alphanumeric quote number
//             const generateAlphanumericQuote = () => {
//                 const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//                 let quote = 'Q';
//                 for (let i = 0; i < 6; i++) {
//                     quote += chars.charAt(Math.floor(Math.random() * chars.length));
//                 }
//                 return quote;
//             };
//
//             const shortQuoteNumber = generateAlphanumericQuote();
//             setQuoteNumber(shortQuoteNumber);
//
//             // Generate the email HTML content
//             const emailHtmlContent = `
//                 <h1>Your Sukkah Quote Confirmation</h1>
//                 <p><strong>Service:</strong> ${selections.service}</p>
//                 <p><strong>Sukkah Type:</strong> ${selections.sukkahType}</p>
//                 <p><strong>Dimensions:</strong> ${selections.dimensions}</p>
//                 <p><strong>Total Price:</strong> $${selections.price}</p>
//                 <p><strong>Quote Number:</strong> ${shortQuoteNumber}</p>
//             `;
//
//             console.log('Email HTML Content:', emailHtmlContent);
//             console.log('Contact Info:', contactInfo);
//
//             // Send the email using Gmail API
//             sendGmailEmail(contactInfo.email, 'Your Sukkah Quote Confirmation', emailHtmlContent);
//         }
//     }, [isLoading, selections, contactInfo]);
//
//     const { dimensions, sukkahType, service, price } = selections;
//
//     return (
//         <Step step={5} activeStep={activeStep} title={isLoading ? "Calculating Price..." : "Here's Your Summary 📋"}>
//             {isLoading ? (
//                 <div className="spinner"></div>
//             ) : (
//                 <div className="summary-container">
//                     <div className="summary-header">
//                         <h2>Total Price:</h2>
//                         <span className="summary-price">${price}</span>
//                     </div>
//                     <div className="summary-details">
//                         <h3>Your Selections:</h3>
//                         <ul>
//                             <li><FontAwesomeIcon icon={faTools} className="summary-icon" /><strong>Service:</strong> {service}</li>
//                             <li><FontAwesomeIcon icon={faHome} className="summary-icon" /><strong>Sukkah Type:</strong> {sukkahType}</li>
//                             <li><FontAwesomeIcon icon={faRuler} className="summary-icon" /><strong>Dimensions:</strong> {dimensions}</li>
//                         </ul>
//                     </div>
//                     <div className="summary-divider"></div>
//                     <div className="summary-contact">
//                         <h3>Contact Information:</h3>
//                         <ul>
//                             <li><FontAwesomeIcon icon={faUser} className="summary-icon" /><strong>Name:</strong> {contactInfo.fullName}</li>
//                             <li><FontAwesomeIcon icon={faEnvelope} className="summary-icon" /><strong>Email:</strong> {contactInfo.email}</li>
//                             <li><FontAwesomeIcon icon={faMapMarkerAlt} className="summary-icon" /><strong>ZIP Code:</strong> {contactInfo.zipCode}</li>
//                             <li><FontAwesomeIcon icon={faPhone} className="summary-icon" /><strong>Phone:</strong> {contactInfo.phone}</li>
//                         </ul>
//                     </div>
//                     <div className="summary-divider"></div>
//                     <div className="summary-quote">
//                         <h3>Quote Number:</h3>
//                         <span>{quoteNumber}</span> {/* Display the generated quote number */}
//                     </div>
//                 </div>
//             )}
//         </Step>
//     );
// }
//
// export default Step5;
